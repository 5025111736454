import { createContext, useContext } from 'react'

import useSWR from 'swr';

import axiosInstance from './axiosInstance';


const AuthContext = createContext({});


export const AuthProvider = ({ children }) => {

  const { data, error, mutate } = useSWR('/api/v2/business-user-basic/');

  const logout = async () => {
    await axiosInstance.post(`/api/v2/logout/`);
    mutate('/api/v2/business-user-basic/', null);
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
    window.location = '/';
  }

  return (
    <AuthContext.Provider value={{
      isAuthenticated: !!data && !error,
      user: data,
      loading: !error && !data,
      logout,
    }}>
      {children}
    </AuthContext.Provider>
  )
}

export default function useAuth() {
  const context = useContext(AuthContext)

  return context
};
