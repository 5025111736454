import { useEffect } from 'react';

import PropTypes from 'prop-types';

import Head from 'next/head';
import Script from 'next/script';
import { useRouter } from 'next/router';

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider } from '@emotion/react';

import { SWRConfig } from 'swr';

import { SnackbarProvider } from 'notistack';

import theme from '../src/theme';
import fetcher from '../src/fetcher';
import createEmotionCache from '../src/createEmotionCache';

import { AuthProvider } from '../src/auth';


// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();


export default function HubApp(props) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url) => {
      window.gtag('config', process.env.GA_TRACKING_ID, {
        page_path: url
      })
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <CacheProvider value={emotionCache}>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GA_TRACKING_ID}`}
      />
      <Script
        id="page-load"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
      <Head>
        <title>Hub</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>

      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SWRConfig
          value={{
            fetcher: fetcher,
            refreshInterval: 1000 * 60 * 5,
          }}
        >
          <AuthProvider>
            <SnackbarProvider
              maxSnack={3}
              classess={{
                variantinfo: { backgroundColor: 'purple' }
              }}
            >
              <Component {...pageProps} />
            </SnackbarProvider>
          </AuthProvider>
        </SWRConfig>
      </ThemeProvider>
    </CacheProvider>
  );
}

HubApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  emotionCache: PropTypes.object,
  pageProps: PropTypes.object.isRequired,
};
